import React, { useEffect, useMemo, useState } from 'react';
import {
    TRichTextDataObject,
    useRichTextDataTypeAnalyzer,
} from './helper/useRichTextDataTypeAnalyzer';
import { Descendant } from 'slate';
import richTextStyles from './SlateEditor.module.scss';

export enum ERichTextStyles {
    descriptionStyles = 'descriptionStyles',
    pageStyles = 'pageStyles',
    pageInvertStyles = 'pageInvertStyles',
    cmsTitleStyles = 'cmsTitleStyles',
    cmsTitleInvertedStyles = 'cmsTitleInvertedStyles',
}

export interface IRichTextDisplay {
    content: string | null | undefined;
    invertStyles?: boolean;
    styles?: ERichTextStyles;
    testId?: string;
    richTextClasses?: string;
}

const isJSON = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const RichTextDisplay: React.FC<IRichTextDisplay> = (props) => {
    const { content, invertStyles = false, styles, richTextClasses = '' } = props;

    const { parseSlateToHTML } = useRichTextDataTypeAnalyzer();

    const [html, setHtml] = useState<string | null>(null);

    const invertModifier = useMemo(() => {
        return invertStyles ? (richTextStyles.SlateEditorInverted as string) : '';
    }, [invertStyles]);

    useEffect(() => {
        if (content) {
            // Plain Text Bailout
            if (!isJSON(content)) {
                setHtml(content);
                return;
            }

            // Parse JSON
            const description = JSON.parse(content) as TRichTextDataObject;

            if (description && description.type === 'html') {
                // Replace this with ""
                const replacer = [
                    "<tr><td class='d_td1'>Verf&uuml;gbar:</td><td class='d_td2 verfuegbar'>x</td></tr>",
                ];

                let descriptionHTML = description.content;

                replacer.forEach((el) => {
                    descriptionHTML = descriptionHTML.replace(el, '');
                });

                setHtml(descriptionHTML);
            } else if (description && description.type === 'slatejs') {
                setHtml(parseSlateToHTML(description.content as unknown as Descendant[], styles));
            }
        }
    }, [content, parseSlateToHTML, styles]);

    return (
        <>
            {/* HTML Version */}
            {html && (
                <div
                    data-test-id={`${props?.testId ?? ''}-RichTextDisplay`}
                    className={`${richTextStyles.SlateEditor as string} ${invertModifier} ${
                        styles ?? ERichTextStyles.descriptionStyles
                    } ${richTextClasses} relative flex w-full flex-col gap-0`}
                    dangerouslySetInnerHTML={{ __html: html }}
                />
            )}
        </>
    );
};
