import { useCallback } from 'react';
import { Descendant } from 'slate';
import { deserialize } from './serializer';
import * as Yup from 'yup';
import { isJSON } from '../../../../../helper/isJSON';
import { useDisplayStyles } from '../../../../frontend/areas/CMSPage/slateElementMapper/useDisplayStyles';
import { ERichTextStyles } from '../RichTextDisplay';

export interface ITagStyles {
    [key: string]: string;
}
export type TRichTextTypes = 'html' | 'slatejs';

export type TRichTextDataObject = {
    type: TRichTextTypes;
    content: string;
};

// Define the validation for TRichTextTypes as required
const TRichTextTypesSchema = Yup.string().required('Type is required.');

// Define the Yup validation schema for TRichTextDataObject
const TRichTextDataObjectSchema = Yup.object().shape({
    type: TRichTextTypesSchema,
    content: Yup.string().required('Content is required.'),
});

export const useRichTextDataTypeAnalyzer = () => {
    const { getStyles } = useDisplayStyles();

    // Fallback for SlateJS
    const fallbackSlate = useCallback((content?: string): Descendant => {
        return {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type: 'paragraph',
            children: [{ text: content ?? '' }],
        };
    }, []);

    const getRichTextDataType = (richText: TRichTextDataObject): string | Descendant[] => {
        switch (richText.type) {
            case 'html':
                return richText.content;
            case 'slatejs':
                return JSON.parse(richText.content) as Descendant[];
            default:
                return '';
        }
    };

    const convertAsRichTextDataType = useCallback(
        (data: string, type: TRichTextTypes): TRichTextDataObject => {
            return {
                type,
                content: data,
            };
        },
        []
    );

    /**
     * HERE ARE THE RICHTEXT DISPLAY STYLES DEFINED
     */
    const parseSlateToHTML = useCallback(
        (content: Descendant[], styles?: ERichTextStyles): string => {
            return getStyles(content, styles ?? ERichTextStyles.pageStyles);
        },
        [getStyles]
    );

    const parseHTML = useCallback((content: string): Descendant[] => {
        const parsed = new DOMParser().parseFromString(content, 'text/html');
        const children = parsed.body.childNodes;
        const childArr = [];
        for (let i = 0; i < children.length; i++) {
            if (children.length > 0) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                childArr.push(deserialize(children[i]));
            }
        }
        return childArr;
    }, []);

    const getContentStringForSlate = useCallback(
        (richText: TRichTextDataObject | string): Descendant[] => {
            // If it's a string, check if it's a valid JSON Schema
            if (!isJSON(richText as string) && typeof richText === 'string') {
                // If it's a string, check if it's a valid JSON Schema
                if (richText.startsWith('<')) {
                    return parseHTML(richText);
                } else {
                    // Plaintext - Return it into a SlateJS compatible format - simple paragraph
                    return [fallbackSlate(richText)];
                }
            }

            // If it's a string, check if it's a valid JSON Schema
            if (isJSON(richText as string) && JSON.parse(richText as string)) {
                return [fallbackSlate()];
            }

            // If it's a string, check if it's a valid JSON Schema
            if (
                isJSON(richText as string) &&
                !TRichTextDataObjectSchema.isValidSync(JSON.parse(richText as string))
            ) {
                // Null / undefined / empty string - Return fallback
                if (!richText) return [fallbackSlate()];

                // Is plain text, maybe HTML - Return it
                return parseHTML(richText as string);
            }

            // ### Default way - Determine the type and parse it
            const richTextData =
                typeof richText === 'string'
                    ? (JSON.parse(richText) as TRichTextDataObject)
                    : richText;

            if (richTextData?.type === 'slatejs') {
                if (!richTextData.content) {
                    return [fallbackSlate()];
                }

                return richTextData.content as unknown as Descendant[];
            }

            if (richTextData?.type === 'html') {
                parseHTML(richTextData.content);
            }

            // Fallback
            return [fallbackSlate()];
        },
        [fallbackSlate, parseHTML]
    );

    return {
        getRichTextDataType,
        convertAsRichTextDataType,
        getContentStringForSlate,
        parseSlateToHTML,
        fallbackSlate,
    };
};
