/* eslint-disable */
// @ts-nocheck
import { Descendant, Node } from 'slate';
import { ITagStyles } from './useRichTextDataTypeAnalyzer';

/**
 * TODO This is a prototype for a serializer/deserializer for HTML to Slate and vice versa.
 * Made with AI, must be reworked on a later point; it does work so far
 */

// Adjust this to match with the nodes that you use
const ELEMENT_TAGS = {
    BLOCKQUOTE: () => ({ type: 'quote' }),
    P: () => ({ type: 'paragraph' }),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    A: (el) => ({ type: 'link', url: el.getAttribute('href') }),
    TABLE: (): object => ({ type: 'table' }),
    TBODY: (): object => ({ type: 'table-body' }),
    TR: (): object => ({ type: 'table-row' }),
    TD: (): object => ({ type: 'table-cell' }),
    TH: (): object => ({ type: 'table-header' }),
};

// Similarly, adjust this for the marks that you use
const TEXT_TAGS = {
    CODE: () => ({ code: true }),
    EM: () => ({ italic: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true }),
};

export const deserialize = (el: Node): Descendant | string => {
    if (el.nodeType === 3) {
        // Text node
        return { text: el.textContent || '' };
    } else if (el.nodeType === 1) {
        // Element node
        const { nodeName } = el;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const children = Array.from(el.childNodes)
            .map(deserialize)
            .filter((child) => typeof child === 'object') as Descendant[];

        if (ELEMENT_TAGS[nodeName]) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
            const attrs = ELEMENT_TAGS[nodeName](el);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            return { type: attrs.type, children: children.length ? children : [{ text: '' }] };
        }

        if (TEXT_TAGS[nodeName]) {
            const attrs = TEXT_TAGS[nodeName](el);
            let textContent = el.textContent || '';

            // Deserialize children, combine adjacent "text" nodes with the same "bold" attribute
            const children = Array.from(el.childNodes).reduce(
                (result: Descendant[], child: Node) => {
                    const childNode = deserialize(child);

                    if (Array.isArray(childNode)) {
                        result = result.concat(childNode);
                    } else {
                        const prevNode = result[result.length - 1];
                        if (prevNode && prevNode.text && prevNode.bold === attrs.bold) {
                            prevNode.text += childNode.text;
                        } else {
                            result.push(childNode);
                        }
                    }
                    return result;
                },
                [] as Descendant[]
            );
            return children.length ? children : ([{ ...attrs, text: textContent }] as Descendant[]);
        }

        // Fallback for unhandled nodes; treat them as paragraphs to maintain document structure
        return { type: 'paragraph', children: children.length ? children : [{ text: '' }] };
    }
    // Fallback for other node types or errors
    return { text: '' };
};

function escapeHtml(string: string) {
    return string
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;');
}

// Each entry in the SLATE_TO_TAGS corresponds to a tag mapping, the key is
// the kind of the Slate node and the value is a function that returns
// an object with the type property set to the corresponding HTML tag name
const SLATE_TO_TAGS = {
    'heading-one': () => ({ type: 'h1', style: 'color: red' }),
    'heading-two': () => ({ type: 'h2' }),
    list: () => ({ type: 'ul' }),
    'list-item': () => ({ type: 'li' }),
    'numbered-list': () => ({ type: 'ol' }),
    bold: () => ({ type: 'b' }),
    strong: () => ({ type: 'strong' }), // Additional emphasis
    link: () => ({ type: 'a' }),
    underline: () => ({ type: 'u' }), // Represents span of text with semantic "underline" meaning.
    paragraph: () => ({ type: 'p' }),
    // Add more mappings as per your requirement
};

export const serialize = (nodes: Node[] = [], tagStyles: ITagStyles): string => {
    return nodes
        .map((n: any) => {
            if ('text' in n) {
                let text = escapeHtml(n.text);
                if (n.bold) {
                    text = `<strong>${text}</strong>`;
                }
                return text;
            }
            const mappedTag = SLATE_TO_TAGS[n.type]?.();

            let styles = '';

            // Apply color style, if tag is a key in tagStyles example h1:#123545; skip if curreent tag isnt in the keys
            if (mappedTag && tagStyles && Object.keys(tagStyles).includes(mappedTag.type)) {
                const colorCode = tagStyles[mappedTag.type];
                styles = `color: ${colorCode} !important;`;
            }

            if (mappedTag) {
                return `<${mappedTag.type} style="${styles}">${serialize(n.children)}</${
                    mappedTag.type
                }>`;
            }
            return `<${n.type}>${serialize(n.children)}</${n.type}>`;
        })
        .join('');
};
