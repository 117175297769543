import { useCallback } from 'react';
import { Descendant } from 'slate';
import { serialize } from '../../../../general/controls/RichText/helper/serializer';
import { useThemeStyles } from '../../../../general/theme/TenantTheme/hooks/useThemeStyles';
import { ERichTextStyles } from '../../../../general/controls/RichText/RichTextDisplay';

export const useDisplayStyles = () => {
    const { applyStyles } = useThemeStyles();

    const getStyles = useCallback(
        (content: Descendant[], style: ERichTextStyles): string => {
            switch (style) {
                case ERichTextStyles.cmsTitleStyles:
                    return serialize(content, {
                        h1:
                            applyStyles({
                                textColor: 'colors.primary.DEFAULT',
                            })?.color ?? '',
                        p:
                            applyStyles({
                                textColor: 'colors.primary.DEFAULT',
                            })?.color ?? '',
                    });
                case ERichTextStyles.cmsTitleInvertedStyles:
                    return serialize(content, {
                        h1:
                            applyStyles({
                                textColor: 'colors.invert.primary',
                            })?.color ?? '',
                        p:
                            applyStyles({
                                textColor: 'colors.invert.info',
                            })?.color ?? '',
                    });
                case ERichTextStyles.pageInvertStyles:
                    return serialize(content, {
                        h1:
                            applyStyles({
                                textColor: 'colors.primary.invert',
                            })?.color ?? '',
                        h2:
                            applyStyles({
                                textColor: 'colors.primary.invert',
                            })?.color ?? '',
                        a:
                            applyStyles({
                                textColor: 'colors.primary.invert',
                            })?.color ?? '',
                        bold:
                            applyStyles({
                                textColor: 'colors.primary.invert',
                            })?.color ?? '',
                        p:
                            applyStyles({
                                textColor: 'colors.body.invert',
                            })?.color ?? '',
                        span:
                            applyStyles({
                                textColor: 'colors.body.invert',
                            })?.color ?? '',
                    });
                case ERichTextStyles.pageStyles:
                default:
                    return serialize(content, {
                        h1:
                            applyStyles({
                                textColor: 'colors.primary.DEFAULT',
                            })?.color ?? '',
                        h2:
                            applyStyles({
                                textColor: 'colors.primary.DEFAULT',
                            })?.color ?? '',
                        a:
                            applyStyles({
                                textColor: 'colors.primary.DEFAULT',
                            })?.color ?? '',
                        bold:
                            applyStyles({
                                textColor: 'colors.primary.DEFAULT',
                            })?.color ?? '',
                        p:
                            applyStyles({
                                textColor: 'colors.body.DEFAULT',
                            })?.color ?? '',
                        span:
                            applyStyles({
                                textColor: 'colors.body.DEFAULT',
                            })?.color ?? '',
                    });
            }
        },
        [applyStyles]
    );

    return { getStyles };
};
